<template>
  <div class="error-page">
    <img src="@/assets/images/404.jpg" alt />
    <h1 class="content">{{ $t("errorPage.pageNotFound.content") }}</h1>
    <p class="note">{{ $t("errorPage.pageNotFound.note1") }}</p>
    <p class="note">{{ $t("errorPage.pageNotFound.note2") }}</p>
    <el-button type="text" class="mt-2" @click="goBackHome">{{
      $t("buttons.goBackHome")
    }}</el-button>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
  data() {
    return {
      window
    }
  },
  methods: {
    goBackHome() {
      this.$router.push({ path: '/' })
    }
  }
}
</script>

<style lang="scss" scoped></style>
